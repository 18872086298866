<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="模板名称" prop="templatename" style="width: 25%">
              <a-input v-model.trim="queryParams.templatename" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="width: 20%;float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="scenetemplateid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="detail" slot-scope="value, record">
            <a-button type="primary" size="small" @click="showDetail('detail',record.templatename,record.scenetype,record.scenetemplateid)">模板详情</a-button>
          </span>
          <span slot="edit" slot-scope="value, record">
            <a-button type="primary" size="small" @click="showDetail('edit',record.templatename,record.scenetype,record.scenetemplateid)">模板修改</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
<!--                <a-menu-item :key="'detail-'+record.scenetemplateid">详情</a-menu-item>-->
                <a-menu-item :key="'edit-'+record.scenetemplateid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.scenetemplateid" v-if="record.scenetemplateid>10">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="addModalVisible" :show-type="addModalShowType" :detailData="addModalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    <scene-template-detail :visible.sync="modalVisible" :detailData="modalDetailData"></scene-template-detail>
  </page-container>
</template>

<script>
import areaselect from "@/mixins/areaselect";
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
import moment from "moment";
import {mapState} from "vuex";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import SceneTemplateDetail from "C/modals/SceneTemplateDetail";
import {deleteSceneTemplate, getSceneTemplateListByCondition} from "A/jcgn";
import addOrEditModal from "V/SceneManagement/waterSystem/addOrEditModal";
import {deleteMonitorPoint} from "A/monitoring";
export default {
  name: "electricitySystem",
  mixins: [areaselect, deptselect, pagination],
  components: {
    SceneTemplateDetail,
    addOrEditModal
  },
  data() {
    return {
      moment,
      showAdvanced: false,
      queryParams: {
        scenetemplateid:'',
        monitorpointnum: '',
        templatename:'',
        scenetype:2,
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '模板名称',
          dataIndex: 'templatename',
          key: 'templatename',
          ellipsis: true,
        },
        {
          title: '',
          key: 'detail',
          width: 100,
          scopedSlots: { customRender: 'detail' }
        },
        {
          title: '',
          key: 'edit',
          width: 100,
          scopedSlots: { customRender: 'edit' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: {
        scenetemplateid:'',
        templatename:'',
        scenetype:'',
      },
      addModalVisible: false,
      addModalShowType: '',
      addModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '电系统模板管理';
    },
    breadcrumb() {
      return [
        {
          name: '场景管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.init();
    this.initDeptOptionsAll();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getSceneTemplateListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showDetail(type,templatename,scenetype,scenetemplateid){
      getSceneTemplateListByCondition({templatename:templatename,scenetype:scenetype,scenetemplateid:scenetemplateid}).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            if(type=='detail'){
              this.modalDetailData.scenetemplateid=scenetemplateid
              this.modalDetailData.templatename=templatename
              this.modalDetailData.scenetype=scenetype
              this.modalVisible=true
            }else if(type=='edit'){
              let prePages = [
                {
                  name: '场景管理',
                  path: ''
                },
                {
                  name: '模板设置',
                  path: 'dragToGenerate'
                }
              ];
              this.$router.push({
                name: 'dragToGenerate',
                params: {
                  templatename:templatename,
                  scenetype:scenetype,
                  scenetemplateid:scenetemplateid,
                  prePages: prePages
                }
              })
            }
          }else{
            this.$message.error("该项目不存在电系统场景模板")
          }
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let scenetemplateid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'scenetemplateid', scenetemplateid);
      if(type == 'detail') {
        this.addModalShowType='detail'
        this.addModalDetailData=record
        this.addModalVisible=true
      }else if(type == 'edit') {
        this.addModalShowType='edit'
        this.addModalDetailData=record
        this.addModalVisible=true
      }else if(type == 'delete') {
        this.deleteConfirm("",record)
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.scenetemplateid);
      }).catch(()=>{
      });
    },
    delete(scenetemplateid) {
      this.showLoading();
      if(scenetemplateid) {
        let params = {
          scenetemplateid
        };
        deleteSceneTemplate(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>